<template>
    <!--  v-if="list.length>0" -->
    <div class="RWGSBox">
        <iframe src="https://roma.720yun.com/vr/5dd775fbe80423c9" height="100%" width="100%" frameborder="0" allowfullscreen="true" ref="obj"></iframe>
    </div>
</template>
<script>
import $ from 'jquery'
var t
export default {

}
</script>
<style lang="less" scoped>
.RWGSBox {
    position: fixed;
    top: 6vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}
</style>